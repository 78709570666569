import React from 'react';

const container = {
  height: "100%",
  display: "flex",
  flexDirection: "column",
  paddingTop: 27
};

const gursharan = {
  fontSize: "45px",
  color: "white",
  textAlign: "center"
};

const singh = {
  fontSize: "18px",
  color: "white",
  textAlign: "center",
  whiteSpace: "pre-line"
};

function Item(props) {
  return (
    <div style={{ display: "flex", alignSelf: "center" }}>
      <div style={props.style}>{props.title}</div>
    </div>
  )
}

function Icon(props) {
  const heightStyle = { height: props.height ?? 81, width: props.height ? null : 81 }
  const style = { ...heightStyle, marginTop: 15, backgroundColor: props.color, padding: props.color ? 9 : 0, borderRadius: 9 }
  return (
    <div style={{ display: "flex", alignItems: "center", flexDirection: "column" }}>
      {props.title && <div style={singh}>{props.title}</div>}
      <a aria-label={props.alt} href={props.url} style={style} target="_blank"><img alt={props.alt} style={heightStyle} src={props.source} /></a>
    </div>
  )
}

function getRandomInt(max) {
  return Math.floor(Math.random() * max);
}

function App() {
  const tech = `For your \n rational desires`;
  const games = `For your \n irrational desires`;
  const videos = [
    "https://www.youtube.com/embed/UHrFHTGP5i4?si=B0BdoXhsSh4iqssf&amp;start=2",
    "https://www.youtube.com/embed/96NUsT-1k2A?si=dfLJqxTlpfhSeA_2;start=2"
  ]

  return (
    <div style={container}>
      <Item style={gursharan} title={"Gursharan Singh"} />
      <div style={{ height: 15 }} />
      <Item style={singh} title={"Sr. Engineer"} />
      <Item style={singh} title={"Amazon Prime Video"} />
      <Icon alt="Prime Video" color={"#ECEFF1"} height={39} source='https://upload.wikimedia.org/wikipedia/commons/1/11/Amazon_Prime_Video_logo.svg' url={"https://www.amazon.com/gp/video/storefront"} title={""} />
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 40, gap: 50 }}>
        <Icon alt="Linked In" source='linkedin.svg' url={"https://www.linkedin.com/in/gursharan81"} title={tech} />
        <Icon alt="Overwatch" source='overwatch.svg' url={"https://overwatch.blizzard.com/en-us/career/fc57a483bf3ccafcbca324%7C4c386fda1a9a0a1005dc1e9e89551dee/"} title={games} />
      </div>
      <div style={{ display: "flex", flexDirection: "row", justifyContent: "center", marginTop: 40 }}>
        <iframe
          width="400"
          height={300}
          src={videos[getRandomInt(videos.length)]}
          title="YouTube video player"
          frameborder="0"
          style={{ borderRadius: 9 }}
          allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
          referrerpolicy="strict-origin-when-cross-origin"
          allowfullscreen></iframe>
      </div>
    </div>
  );
}

export default App;
